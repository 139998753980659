<template>
  <div class="fixed-top" style="z-index:500;">
    <transition name="fade" v-if="toggle">
      <div class="pop-up fixed-top" name="fade" >
        <div class="login-box pop-box">
          <img src="../assets/ecity/BSLC_LOGO_B 1.png" alt="">
          <font-awesome-icon class="pointer" @click="close" size="2x" :icon="['fa', 'times']"  style="color:rgba(255, 0, 0, .6);float: right;"/>
          <div class="mt-5">
            <form class="mt-4 px-4">
              <div class="group">
                <input v-on:keyup.enter="login" v-model="auth.account" type="text" required>
                <span class="highlight"></span>
                <span class="bar"></span>
                <label>請輸入電子信箱..</label>
              </div>
              <div class="group">
                <input v-on:keyup.enter="login" v-model="auth.password" type="password" required>
                <span class="highlight"></span>
                <span class="bar"></span>
                <label>請輸入密碼...</label>
              </div>
              <button type="button" style="width:100%" class="btn btn-danger" @click="login">{{ $t('Login.login') }}</button>
            </form>
          </div>
        </div>
      </div>
    </transition>
    <nav class="navbar navbar-light bg-light">
      <img class="pointer" style="width:120px" @click="$router.push({ name: 'Login'})" src="../assets/ecity/BSLC_LOGO_B 1.png" alt="">
      <div>
        <span class="mx-sm-2">
          <span v-if="authorized" @click="goPage('personal')" class="pointer">個人專區</span>
        </span>
        <span v-if="authorized">
          |
        </span>
        <span class="mx-2">
          <span v-if="!authorized" @click="toggle=true" class="pointer">{{ $t('Login.login') }}</span>
          <span v-else @click="logout" class="pointer">登出</span>
        </span>
      </div>
    </nav>
    <nav class="navbar navbar-expand-lg navbar-light bg-light main py-0">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav" v-if="channels.length>0">
          <li class="nav-item dropdown" v-for="(item, index) in channels" :key="item.id">
            <a class="nav-link" role="button" data-toggle="dropdown" @mouseenter="selectedMain=null,main =index">
              <!-- {{item.name}}{{item.name_en}} -->
              {{locale==="tw"?item.name:item.name_en}}
            </a>
            <div :class="'main-menu'+index" class="dropdown-menu dropright main-menu m-0" style="">
              <div class="main-menu-item" v-for="(el, index) in item.child"  @mouseover="mouseHover(el,index)" :key="index">
                <a @click="goPage(el.id)" class="dropdown-item pointer d-flex justify-content-between" :class="{'active': el.id === selectedMain}">
                  <div class="menu-item" >
                    <!-- {{el.name}}
                    {{el.name_en}} -->
                    {{locale==="tw"?el.name:el.name_en}}
                  </div>
                  <div v-if="el.items.length>0" class="pl-5 arrow">
                    <font-awesome-icon class="pointer chevron" size="lg" :icon="['fa', 'chevron-right']" />
                  </div>
                </a>
                <div>
                  <div class="dropdown-menu sub-menu m-0" v-if="el.items.length>0" :style="{'height':adjustment}">
                    <div class="nav-item" v-for="(item, index) in el.items" :key="index">
                      <a class="dropdown-item pointer" @click.self="goSubPage(item.id)" >
                        <!-- {{item.name}}
                        {{item.name_en}} -->
                        {{locale==="tw"?item.name:item.name_en}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import api from '../store/api'
export default {
  name: 'Navbar',
  data () {
    return {
      auth: {
        account: '',
        password: ''
      },
      toggle: false,
      locale: window.localStorage.getItem('locale') || 'en',
      channels: [],
      submenu: [],
      search: '',
      selectedMain: null,
      adjustment: '',
      main: ''
    }
  },
  computed: {
    ...mapGetters([
      'authorized'
    ])
  },
  watch: {
  },
  mounted () {
    this.getChannel()
  },
  methods: {
    ...mapActions({
      dologin: 'login',
      dologout: 'logout'
    }),
    goPage (id) {
      if (id === 'personal') {
        // this.$router.push({ name: 'Target'})
        this.$router.push({ name: 'Record'})
      } else {
        this.autoNarbar()
        this.$router.push({ name: 'Channels', params: { channel: id } })
        this.submenu = []
      }
    },
    autoNarbar () {
      if (document.body.clientWidth < 900) {
        const navBtn = document.querySelector('.navbar-toggler')
        navBtn.click()
      }
    },
    navbarHover () {
      document.addEventListener("DOMContentLoaded", function(){
      if (window.innerWidth > 992) {
          document.querySelectorAll('.navbar .nav-item').forEach(function(everyitem) {
            everyitem.addEventListener('mouseover', function(e){
              let el_link = this.querySelector('a[data-bs-toggle]')
              if(el_link != null){
                let nextEl = el_link.nextElementSibling
                el_link.classList.add('show')
                nextEl.classList.add('show')
              }
            })
            everyitem.addEventListener('mouseleave', function(e){
              let el_link = this.querySelector('a[data-bs-toggle]')
              if (el_link != null) {
                let nextEl = el_link.nextElementSibling
                el_link.classList.remove('show')
                nextEl.classList.remove('show')
              }
            })
          })
        }
      })
    },
    goSubPage (id) {
      this.$router.push({ name: 'Course', params: { channel: id } })
    },
    goBiz () {
      this.$router.push({ name: 'Biz-talk'})
    },
    async getChannel () {
      const { data } = await api.get('/contents/channels')
      this.channels = data.data
    },
    async getSubChannel (id) {
      const { data } = await api.get(`/contents/channels/${id}`, { headers: {'showLoading': false }} )
      this.submenu = data.data.items
    },
    close () {
      this.toggle = false
    },
    async login () {
      const vm = this
      try {
        await this.dologin({ email: this.auth.account, password: this.auth.password })
        this.toggle = false
      } catch (error) {
        if (error) {
          this.setTimeout(
            this.$swal({
            title: '登入失敗',
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: '確定'
          })
          , 1000)
        }
      }
    },
    logout () {
      this.$router.push({ name: 'Login' })
      this.dologout()
    },
    doSearch () {
      this.$router.replace({ name: 'Search', query: {q: this.search} })
    },
    mouseHover (el,index) {
      this.selectedMain = el.id
      if (index+1 > el.items.length) {
        this.adjustment = document.querySelector(`.main-menu${this.main}`).offsetHeight + 'px'
      } else {
        this.adjustment = ''
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/styles/color.scss";
@import "../assets/styles/custom.scss";
  .group {
    position:relative;
    margin-bottom: 35px;
  }
  .main {
    min-height: 45px;
    background-color: $main-color !important;
  }
  input {
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #757575;
  }
  input:focus	{ outline: none; }

  /* LABEL ======================================= */
  label {
    color: #999;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  /* active state */
  input:focus ~ label, input:valid ~ label {
    top: -20px;
    font-size: 14px;
    color: #000000;
  }
  .pop-up {
    text-align: left;
    width: 100%;
    height: 103vh;
    background-color: #000000ad !important;
    z-index: 1040;
    .login-box {
      @include center();
      padding: 40px;
      border-radius: 20px;
      box-shadow: 10px;
      background-color: #fff;
      width: 580px;
      max-height: 580px;
      box-shadow: 10px 12px 12px 12px rgba(0, 0, 0, 0.4);
      z-index: 1000;
      margin: auto;
    }
  }
  .navbar {
    a{
      font-size: 1.2rem;
      color: $white-color !important;
    }
    z-index: 0;
    }
  .dropdown-menu {
    background-color: $light-gray;
    // text-align: center;
    text-align: left;
    .main-title {
      line-height: 3rem;
      font-weight: bolder;
      border-bottom: black 1px solid;
    }
    a {
      color: #000 !important;
      .arrow {
        display: none;
      }
    }
    // .dropdown-item:hover,:active {
    //   background-color: $sub-color !important;
    //   color: #ffff !important;
    // }
    .active {
      color: #ffff !important;
      background-color: $sub-color !important;
    }
  }
  .search {
    width: 100%;
    position: relative;
    .fa-search {
      margin-left: 5px;
      background-color: #fff;
      cursor: pointer;
      color: rgb(117, 117, 117);
    }
  }

  .sub-menu {
    // min-height: 50vh;
    max-height: 80vh;
    overflow: auto;
    background-color: $light-gray!important;
    .dropdown-item:hover,:active {
      background-color: $gray-color !important;
      color: #ffff !important;
    }
  }
  .topic-item:hover {
    color: #ffff !important;
    background-color: $sub-color !important;
  }
  @media all and (min-width: 992px) {
    .navbar .nav-item .dropdown-menu{ display: none; }
    .navbar .nav-item:hover .main-menu{ display: block; }
    .navbar .main-menu .main-menu-item:hover .sub-menu{ display: block; }
    .arrow {
      display: block !important;
    }
  }
  @media all and (max-width: 992px) {
    .menu-item {
      margin: auto;
    }
  }
</style>
