<template>
  <div>
    <div v-if="lesson" class="channel-lesson m-auto">
      <live-player :liveData="lesson" v-if="lesson" />
    </div>
    <!-- <div class="assisted col-12">
      <div class="assisted-box">
        <div class="d-flex">66
          <h4 class="mx-3">單字學習</h4>
          <h4>必備句子</h4>
        </div>
        <div>
          <ul class="list-group list-group-flush assisted-info">
            <li class="list-group-item"  v-for="(item, index) in vocabularies" :key="index">
              <h5>{{item.name}}  [ɪnˈdjur] v. 忍受；忍耐</h5>
              <p class="list-text">
                As a child, he was forced to endure poverty and war.
              </p>
              <p class="list-text">
                他小時候被迫忍受貧窮與戰爭。
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
// @ is an alias to /src
// import Video from '@/components/Video.vue'
// import apiLibrary from '../store/library/api'
import api from '../store/api'
import LivePlayer from 'live-player'

export default {
  name: 'CourseLesson',
  data () {
    return {
      timer: null,
      time: 0,
      period: 60,
      lesson: null,
      courseId: this.$route.params.courseId,
      lessonId: this.$route.params.lesson,
      vocabularies: []
    }
  },
  watch: {
    time (val) {
      if (val === this.period) {
        this.recordTime(val)
        this.time = 0
      }
    }
  },
  async beforeDestroy () {
    this.recordTime(this.time)
    this.$emit('isIdle', 'stop')
    clearInterval(this.timer)
  },
  mounted () {
    this.getLesson(this.courseId)
    this.timer = setInterval(this.countUp, 1000)
    this.scrollTop()
  },
  methods: {
    async getLesson (id) {
      const { data } = await api.get(`/contents/lesson/${id}`)
      if (data.status === 'OK') {
        if (data.data.lessonType) {
          this.getLesson(data.data.lessons[0].id)
          this.vocabularies = data.data.lessons[0].modules[0].vocabularies
        } else {
          this.lesson = data.data
          this.vocabularies = data.data.modules[0].vocabularies
        }
      }
    },
    countUp () {
      this.time++
    },
    async recordTime (val) {
      const options = { headers: {'showLoading': false }}
      const fileData = new window.FormData()
      fileData.append('seconds', val)
      const { data } = await api.post(`records/section/${this.lessonId}/time`, fileData, options)
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    }
  },
  components: {
    // Video,
    LivePlayer
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/styles/LivePlayer.css';
@import "../assets/styles/color.scss";
  .channel-lesson {
    // min-height:600px;
    // max-width: 75%;
  }
  .assisted {
    width: 100%;
    padding: 20px;
    background-color: rgb(179, 201, 198);
    .assisted-box {
      text-align: left;
      border-radius: 10px;
      width: 80%;
      padding: 20px;
      margin: 50px auto;
      background-color: #fff;
      .assisted-info {
        padding: 10px;
        height: 400px;
        overflow: auto;
        .list-text {
          margin: 0;
        }
      }
    }
  }
</style>
