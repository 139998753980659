<template>
  <div class="container-fluid my-3 record" style="min-height:50vh">
    <div class="row">
      <div class="col-12">
        <div class="d-flex title-box">
          <!-- <div class="title" :class="{'active': currentPage==='Overview'}"  @click="goPage('Overview')">總覽</div> -->
          <div class="title" :class="{'active': currentPage==='Record'}" @click="goPage('Record')">詳細內容</div>
        </div>
        <div  class="my-2" v-if="currentPage==='Overview'">
          日期區間:
        <date-picker valueType="format" v-model="range" type="date" range placeholder="請選擇日期區間"></date-picker>
        </div>
        <div v-else class="my-2">
          課程分類:
          <select v-model="selectedChannel" class="p-1">
            <option :value="0">全部</option>
            <option v-for="(item, index) in channels" :key="index" :value="item.id">{{item.name}}</option>
          </select>
        </div>
      </div>
    </div>
    <div>
      <div v-if="currentPage=='Overview'">
        <div v-for="(item, index) in summaryRecord" :key="index">
          <div class="d-flex">
            <table class="rwd-table">
              <tbody>
                <tr>
                  <th class="th-title" style="text-align:center;" rowspan="2">
                    <img :src="require('../assets/image/icon&btn/icon_title/'+item.category_name+'.png')" alt="">
                  </th>
                  <th>{{ $t('Personal.Info.Total_study_time') }}</th>
                  <th>完成課數</th>
                  <th>{{ $t('Overview.practice') }}</th>
                  <!-- <th class="pointer" style="text-align:center;background:#DB4D6D;" rowspan="2">開始</th>
                  <th class="pointer" style="text-align:center;background:#DB4D6D;" rowspan="2">詳解</th> -->
                </tr>
                <tr>
                  <td class="rwd-td" style="display:none" :data-th="item.category_name">
                  </td>
                  <td data-th="學習時數">
                    {{item.study_time ? item.study_time : 0}}
                  </td>
                  <td data-th="完成課數">
                    {{item.pass_course ? item.pass_course : 0}}
                  </td>
                  <td data-th="通過課後挑戰數">
                    {{item.complete_practice ? item.complete_practice : 0}}
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <button type="button" style="width:80px" class="btn btn-danger m-1" @click="goPage(item.view_id)">開始</button> -->
            <!-- <button type="button" style="width:80px" class="btn btn-danger m-1">詳解</button> -->
          </div>
        </div>
      </div>
      <div v-else>
        <div v-for="(item, index) in recordInfo" :key="index">
          <div class="d-flex">
            <table class="rwd-table">
              <tbody>
                <tr>
                  <th class="th-title" style="text-align:center; width:350px!important;background-size:cover;" rowspan="2" :style="{ backgroundImage: 'url(' + item.banner + ')' }">
                    <div>
                      {{item.level1}} > {{item.level2}}
                    </div>
                    <h5>{{item.name}}</h5>
                  </th>
                  <th>最近學習時間</th>
                  <th>{{ $t('Personal.Info.Total_study_time') }}</th>
                  <th>單元測驗分數</th>
                </tr>
                <tr>
                  <td class="rwd-td" style="display:none" data-th="回數">
                    {{item.name}}
                  </td>
                  <td data-th="最近學習時間">
                    {{moment(item.recent).format('YYYY/MM/DD HH:mm:ss') }}
                  </td>
                  <td data-th="學習時數">
                    {{item.time}}
                  </td>
                  <td data-th="單元測驗分數">
                    {{item.score? item.score: 0}}
                  </td>
                </tr>
              </tbody>
            </table>
            <button type="button" style="width:100px" class="btn btn-danger m-1" @click="goLesson(item.id)">{{ $t('Home.Go_to_course') }}</button>
          </div>
        </div>
        <pagination v-if="pageCount>1" class="m-auto pt-4" :current="pageIndex" :total="pageCount" v-on:page="toPage"/>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Pagination from './Pagination'
import api from '../store/api'
import moment from 'moment';
export default {
  name: 'Record',
  data () {
    return {
      currentPage: 'Record',
      range: [ moment(new Date(new Date().getTime()- 6 * 24 * 3600 * 1000)).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')],
      channels: [],
      selectedChannel: 0,
      // month: [1,2,3,4,5,6,7,8,9,10,11,12],
      summaryRecord: null,
      recordInfo: null,
      studentId: 0,
      pageIndex: 1,
      pageSize: 10,
      pageCount: 0
    }
  },
  components: {
    Pagination,
    DatePicker
  },
  watch: {
    selectedChannel (val) {
      this.pageIndex = 1
      this.getRecordInfo()
    },
    range(val) {
      this.getSummaryRecord()
    }
  },
  mounted () {
    this.getStudentId()
    this.getChannel()
    this.getSummaryRecord()
    this.getRecordInfo()
  },
  methods: {
    goPage (el) {
      this.currentPage = el
    },
    async getSummaryRecord () {
      const params = {
        beginAt: this.range[0],
        endAt:  this.range[1],
      }
      const { data } = await api.get(`/records/students/${this.studentId}/learning_records`, { params })
      this.summaryRecord = data.data
    },
    async getStudentId () {
      const { data } = await api.get('/me')
      this.studentId = data.student_id
    },
    async getRecordInfo () {
      const params = {
        page: this.pageIndex,
        pageSize: this.pageSize,
        categoryId: this.selectedChannel,
      }
      const { data } = await api.get(`/records/students/${this.studentId}/learning_history`, { params })
      this.recordInfo = data.data
      this.pageCount = data.pageCount
    },
    async getLessonId (id) {
      const { data } = await api.get(`/contents/courses/${id}`)
      if (data.status === 'Success') {
        return data.data
      }
    },
    async goLesson (id) {
      await this.getLessonId(id).then((val) => {
        this.$router.push({ name: 'CourseLesson', params: { lesson: val.id, courseId: val.sections[0].data.view_id||val.sections[0].id } })
      } )
    },
    async getChannel () {
      const { data } = await api.get('/contents/channels')
      this.channels = data.data
    },
    toPage (toPage) {
      this.pageIndex = toPage
      this.getRecordInfo()
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/styles/color.scss";
.record {
  text-align: left;
}
table {
  border-collapse: collapse;
  width: 100%;
  overflow: auto;
}
th {
  overflow:hidden;
  // background: #428bca;
  border: 4px solid #fff;
  color: #fff;
  text-align: center !important;
  padding: 8px;
}
td {
  background-color: rgb(230, 230, 230);
  border: 4px solid #fff;
  text-align: center !important;
  padding: 8px;
}
.th-title {
  width: 220px;
  height: 120px;
  padding: 0 !important;
  position: relative;
  img {
    top:0;
    left: -5px;
    position: absolute;
    width: 105%;
    height: 100%;
  }
}
.title-box {
  margin-bottom: 20px;
  border-top: #aaa solid 1px;
  border-bottom: #aaa solid 1px;
  padding: 10px 0;
  .title {
    color: #aaa;
    margin: 0 50px 0 0;
    cursor: pointer;
  }
  .active {
    color: black;
  }
}
.rwd-table {
  margin: auto;
  min-width: 300px;
  max-width: 100%;
  border-collapse: collapse;
}

.rwd-table tr:first-child {
  border-top: none;
  background: $table-blue;
  color: #fff;
}

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f5f9fc;
}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: #ebf3f9;
}

.rwd-table th {
  display: none;
}

.rwd-table td {
  display: block;
}

.rwd-table td:first-child {
  margin-top: .5em;
}

.rwd-table td:last-child {
  margin-bottom: .5em;
}

.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 120px;
  display: inline-block;
  color: #000;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}

.rwd-table tr {
  border-color: #bfbfbf;
}

.rwd-table th,
.rwd-table td {
  padding: .5em 1em;
}
@media screen and (max-width: 601px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
  .rwd-td {
    display: block !important;
    background-color: #04a2d6;
  }
  button  {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }
  .rwd-table td:before {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: .25em .5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
  .rwd-table th,
  .rwd-table td {
    // padding: 1em !important;
  }
}
</style>
