<template>
  <footer class="footer">
    <div class="container" :class="{'footer-rwd': this.$route.name === 'CourseLesson'}">
      <div class="row py-5">
        <div class="row col-md-12">
          <div class="col-sm-12 mt-3 mt-sm-0">
            <p style="text-align:center">
              <a href="https://bslcity.com.tw/">BSLC入口網站</a>
            </p>
            <p style="text-align:center">
              <a target="_blank" href="https://lin.ee/NkZ0lOb">英語智慧城市共創平台官方line</a>
            </p>
            <p style="text-align:center">
              <a target="_blank" href="https://www.facebook.com/ilearningTWN">英語智慧城市共創平台官方FB</a>
            </p>
            <p style="text-align:center">
              Copyright©Hebron Soft Limited. All Rights Reserved.
            </p>
          </div>
        </div>
        <div class="footer-title m-auto text-center">
          <select v-model="locale" @change="setLocale(locale)">
            <option value="en">English</option>
            <option value="tw">繁體中文</option>
          </select>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
// import store from '../store';
export default {
  name: 'Footer',
  data () {
    return {
      locale: window.localStorage.getItem('locale') || 'en'
    }
  },
  mounted () {
    // console.log(window.navigator.language)
    if (window.navigator.language === 'zh-TW') {
      window.localStorage.setItem('locale', locale || 'tw')
    }
  },
  methods: {
    setLocale (locale) {
      window.localStorage.setItem('locale', locale || 'en')
      this.locale = locale
      this.$store.commit("setLocal")
      this.scrollTop()
      return window.history.go(0)
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/styles/color.scss";
@import "../assets/styles/custom.scss";
  .footer {
    background-color: $sub-color;
    min-height: 150px;
    color: $white-color;
    position: relative;
    .footer{
      text-align: left;
    }
    h4 {
      text-align: left;
      font-weight: bolder;
    }
    p {
      text-align: left;
    }
    .footer-title {
      h3 {
        @include text(5rem, .2rem, white);
      }
    }
  }
  a,a:hover {
    color: #fff;
  }
  @media screen and (max-width: 600px) {
    .footer-rwd {
      display: none;
    }
  }
</style>