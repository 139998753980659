<template>
  <div class="login-bg">
    <transition name="fade" v-if="toggle">
      <div v-if="isLogin" class="pop-up fixed-top" name="fade" >
        <div class="login-box pop-box">
          <img src="../assets/ecity/BSLC_LOGO_B 1.png" alt="">
          <font-awesome-icon class="pointer" @click="close" size="2x" :icon="['fa', 'times']"  style="color:rgba(255, 0, 0, .6);float: right;"/>
          <div class="mt-5">
            <form class="mt-4 px-4">
              <div class="group">
                <input v-on:keyup.enter="login" v-model="auth.account" type="text" required>
                <span class="highlight"></span>
                <span class="bar"></span>
                <label>請輸入電子信箱..</label>
              </div>
              <div class="group">
                <input v-on:keyup.enter="login" v-model="auth.password" type="password" required>
                <span class="highlight"></span>
                <span class="bar"></span>
                <label>請輸入密碼...</label>
              </div>
              <button type="button" style="width:100%" class="btn btn-danger" @click="login">{{ $t('Login.login') }}</button>
            </form>
          </div>
        </div>
      </div>
      <div v-else class="pop-up fixed-top" name="fade" @click.self="close()">
        <div v-if="current === 'taiwan'">
          <div class="map-country">
            <img v-if="currentIndex === 0" src="../assets/image/home/pic_location_NewTaipeiCity.png" alt="">
            <img v-if="currentIndex === 1" src="../assets/image/home/pic_location_HsinchuCounty.png" alt="">
            <img v-if="currentIndex === 2" src="../assets/image/home/pic_location_MiaoliCounty.png" alt="">
            <img v-if="currentIndex === 3" src="../assets/image/home/pic_location_ChanghuaCity.png" alt="">
            <img v-if="currentIndex === 4" src="../assets/image/home/pic_location_YunlinCounty.png" alt="">
            <img v-if="currentIndex === 5" src="../assets/image/home/pic_location_ChiayiCity.png" alt="">
            <img v-if="currentIndex === 6" src="../assets/image/home/pic_location_TainanCity.png" alt="">
            <img v-if="currentIndex === 7" src="../assets/image/home/pic_location_Kaohsiung.png" alt="">
            <img v-if="currentIndex === 8" src="../assets/image/home/pic_location_Nantou.png" alt="">
            <img v-if="currentIndex === 9" src="../assets/image/home/pic_location_HualienCounty.png" alt="">
            <img v-if="currentIndex === 10" src="../assets/image/home/pic_location_Taitung.png" alt="">
            <img v-if="currentIndex === 11" src="../assets/image/home/pic_location_Lienchiang.png" alt="">
          </div>
          <carousel-3d ref="carousel" class="carousel pointer" :controls-visible="false" :perspective="0" :space="1100" :width="800" :height="600">
            <slide class="slide" v-for="(item, index) in Taiwan" :key="index" :index="index">
              <div class="card mx-auto pointer">
                <img :src="item.img" alt="">
                <div class="card-body" @click="goLesson(item.id)">
                  <h5 class="card-title">{{item.city}}</h5>
                </div>
              </div>
            </slide>
          </carousel-3d>
        </div>
        <div v-if="current === 'ecuador'">
          <div class="map-country">
            <img v-if="currentIndex === 0" src="../assets/image/home/pic_location_Ecua_Quito.png" alt="">
            <img v-if="currentIndex === 1" src="../assets/image/home/pic_location_Ecua_Guayaquil.png" alt="">
          </div>
          <carousel-3d ref="carousel" class="carousel pointer" :controls-visible="false" :perspective="0" :space="1100" :width="800" :height="600">
            <slide class="slide"  v-for="(item, index) in Ecuador" :key="index" :index="index">
              <div class="card mx-auto pointer">
                <img :src="item.img" alt="">
                <div class="card-body"  @click="goLesson(item.id)">
                  <h5 class="card-title">{{item.city}}</h5>
                </div>
              </div>
            </slide>
          </carousel-3d>
        </div>
        <div v-if="current === 'thailand'">
          <div class="map-country">
            <img v-if="currentIndex === 0" src="../assets/image/home/pic_location_Thai_bangkok.png" alt="">
          </div>
          <carousel-3d ref="carousel" class="carousel pointer" :controls-visible="false" :perspective="0" :space="1100" :width="800" :height="600">
            <slide class="slide" v-for="(item, index) in 1" :key="index" :index="index">
              <div class="card mx-auto pointer">
                <img src="../assets/image/home/pic_bangkok.jpg" alt="">
                <div class="card-body" @click="goLesson('113')">
                  <h5 class="card-title">曼谷 Bangkok</h5>
                </div>
              </div>
            </slide>
          </carousel-3d>
        </div>
        <div v-if="current === 'indonesia'">
          <div class="map-country">
            <img v-if="currentIndex === 0" src="../assets/image/home/pic_location_Ind_Jakarta.png" alt="">
          </div>
          <carousel-3d ref="carousel" class="carousel pointer" :controls-visible="false" :perspective="0" :space="1100" :width="800" :height="600">
            <slide class="slide" v-for="(item, index) in 1" :key="index" :index="index">
              <div class="card mx-auto pointer">
                <img src="../assets/image/home/pic_Ind_Jakarta.jpg" alt="">
                <div class="card-body" @click="goLesson('127')">
                  <h5 class="card-title">雅加達 Jakarta</h5>
                </div>
              </div>
            </slide>
          </carousel-3d>
        </div>
      </div>
    </transition>
    <nav class="navbar bg-transparent fixed-top">
      <img src="../assets/ecity/BSLC_LOGO_W.png" alt="">
      <div class="mt-2 m-sm-0">
        <!-- <button type="button" class="btn btn-light mx-2">中</button> -->
        <button v-if="!authInfo.authorized" type="button" @click="openLogin()" class="btn btn-light">Login</button>
        <button v-else type="button"  @click="logout()" class="btn btn-light">Logout</button>
      </div>
    </nav>
    <div class="container-fluid p-0">
      <div class="earth">
        <img class="earth-bg" @click="open = !open" src="../assets/image/home/home_bg.jpg" alt="">
        <img v-if="hover==='ecuador'" class="city-bg" @click="open = !open" src="../assets/image/home/home_bg_ecuador.jpg" alt="">
        <img v-if="hover==='taiwan'" class="city-bg" @click="open = !open" src="../assets/image/home/home_bg_taiwan.jpg" alt="">
        <img v-if="hover==='thailand'" class="city-bg" @click="open = !open" src="../assets/image/home/home_bg_thailand.jpg" alt="">
        <img v-if="hover==='indonesia'" class="city-bg" @click="open = !open" src="../assets/image/home/home_bg_indonesia.jpg" alt="">
        <div class="line d-flex justify-content-around rwd-hide">
          <!-- <div class="country">
            <img src="../assets/image/home/home_btn_topbar_point.png" alt="">
            <div class="country-box">
              Mexico
            </div>
          </div> -->
          <div class="country" @mouseover="hover = 'ecuador'" @mouseleave="hover = ''" @click="current='ecuador',toggle=true">
            <img v-if="current==='ecuador'||hover==='ecuador'" style="z-index:100" src="../assets/image/home/home_btn_topbar_point_ecuador.png" alt="">
            <img v-else src="../assets/image/home/home_btn_topbar_point.png" alt="">
            <div class="country-box">
              Ecuador
            </div>
          </div>
          <div class="country" @mouseover="hover = 'thailand'" @mouseleave="hover = ''" @click="current='thailand',toggle=true">
            <img v-if="current==='thailand'||hover==='thailand'" style="z-index:100" src="../assets/image/home/home_btn_topbar_point_thailand.png" alt="">
            <img v-else src="../assets/image/home/home_btn_topbar_point.png" alt="">
            <div class="country-box">
              Thailand
            </div>
          </div>
          <div class="country" @mouseover="hover = 'indonesia'" @mouseleave="hover = ''" @click="current='indonesia',toggle=true">
            <img v-if="current==='indonesia'||hover==='indonesia'" style="z-index:100" src="../assets/image/home/home_btn_topbar_point_indonesia.png" alt="">
            <img v-else src="../assets/image/home/home_btn_topbar_point.png" alt="">
            <div class="country-box">
              Indonesia
            </div>
          </div>
          <div class="country" @mouseover="hover = 'taiwan'" @mouseleave="hover = ''" @click="current='taiwan',toggle=true">
            <img v-if="current==='taiwan'||hover==='taiwan'" style="z-index:100" src="../assets/image/home/home_btn_topbar_point_taiwan.png" alt="">
            <img v-else src="../assets/image/home/home_btn_topbar_point.png" alt="">
            <div class="country-box">
              Taiwan
            </div>
          </div>
        </div>
        <hr class="line" style="background-color:white; height:1px; border:none;"/>
        <div class="ship-area" @click="open = !open" :class="{'isOpen': open}">
          <img class="ship" src="../assets/image/home/home_pic_separate.png" alt="">
          <div class="content container-fluid p-0">
            <div>
              <div class="d-flex justify-content-around mb-4">
                <div class="rwd-title">
                  <button @click="goLesson('142')" type="button" class="btn btn-outline-secondary">Ecuador</button>
                </div>
                <div class="rwd-title">
                  <button @click="goLesson('113')" type="button" class="btn btn-outline-secondary">Thailand</button>
                </div>
                <div class="rwd-title">
                  <button @click="goLesson('127')" type="button" class="btn btn-outline-secondary">Indonesia</button>
                </div>
                <div class="rwd-title">
                  <button @click="goLesson('18')" type="button" class="btn btn-outline-secondary">Taiwan</button>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <div>
                <img style="width:100%" src="../assets/image/home/home_pic_divider_l.png" alt="">
              </div>
              <div>
                <h2>推薦課程</h2>
                <h4>Recommended course</h4>
              </div>
              <div>
                <img style="width:100%" src="../assets/image/home/home_pic_divider_r.png" alt="">
              </div>
            </div>
            <div class="container-fluid" style="width:80%">
              <div class="row">
                <div class="col-lg-6 col-12" v-for="(item, index) in recommendLesson.slice(0,4)" :key="index">
                  <div class="d-sm-flex my-2">
                    <div style="text-align:center;" @click="goCourse(item.id)">
                      <img class="m-auto pointer" style="max-width:265px" :src="item.cover" alt="">
                    </div>
                    <div style="border:solid 1px #ccc;" class="p-2 m-4 m-sm-0">
                      <h4 class="m-0">
                        {{item.name}}
                      </h4>
                      <p class="m-0" style="font-size:1rem">
                        {{item.name_en}}
                      </p>
                      <span v-for="(el, index) in item.cefr.split(',')" :key="index" class="badge badge-danger mx-1 p-1">{{el}}</span>
                      <div class="mt-3" style="height:75%;">
                        <p style="height:150px;text-align:left;overflow: auto;">
                          {{item.description}}
                        </p>
                        <div class="p-2" style="text-align:right;">
                          <button type="button" @click="goCourse(item.id)" class="btn btn-dark">{{ $t('Home.Go_to_course') }}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
// import Video from '@/components/Video.vue'
import api from '../store/api'
import Footer from '../components/Footer.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Login',
  data () {
    return {
      toggle: false,
      open: false,
      isLogin: false,
      hover: '',
      current: '',
      recommendLesson: [],
      auth: {
        account: '',
        password: ''
      },
      channels: [],
      Taiwan: [
        {
          id: 18,
          city: '新北市 New Taipei City',
          img: require('../assets/image/home/country/pic_NewTaipeiCity.jpg')
        },
        {
          id: 62,
          city: '新竹縣 Hsinchu County',
          img: require('../assets/image/home/country/pic_HsinchuCounty.jpg')
        },
        {
          id: 128,
          city: '苗栗縣 Miaoli County',
          img: require('../assets/image/home/country/pic_MiaoliCounty.jpg')
        },
        {
          id: 13,
          city:'彰化縣 Changhua City',
          img: require('../assets/image/home/country/pic_ChanghuaCity.jpg')
        },
        {
          id: 67,
          city: '雲林縣 Yunlin County',
          img: require('../assets/image/home/country/pic_YunlinCounty.jpg')
        },
        {
          id: 29,
          city: '嘉義市 Chiayi County',
          img: require('../assets/image/home/country/pic_ChiayiCity.jpg')
        },
        {
          id: 66,
          city: '臺南市 Tainan City',
          img: require('../assets/image/home/country/pic_TainanCity.jpg')
        },
        {
          id: 164,
          city: '高雄市 Kaohsiung City',
          img: require('../assets/image/home/country/pic_Kaohsiung.jpg')
        },
        {
          id: 161,
          city: '南投縣 Nantou County',
          img: require('../assets/image/home/country/pic_Nantou.jpg')
        },
        {
          id: 1,
          city: '花蓮縣 Hualien County',
          img: require('../assets/image/home/country/pic_HualienCounty.jpg')
        },
        {
          id: 160,
          city: '臺東縣 Taitung County',
          img: require('../assets/image/home/country/pic_Taitung.jpg')
        },
        {
          id: 162,
          city: '連江縣 Lienchiang County',
          img: require('../assets/image/home/country/pic_Lienchiang.jpg')
        }
      ],
      Ecuador: [
        {
          id: 142,
          city: '基多 Quito',
          img: require('../assets/image/home/pic_Ecua_Quito.jpg')
        },
        {
          id: 151,
          city: '瓜亞基爾 Guayaquil',
          img: require('../assets/image/home/country/pic_Ecua_Guayaquil.jpg')
        }
      ]
    }
  },
  props: {
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'authorized',
      'authInfo'
    ]),
    currentIndex: {
      cache: false,
      get() {
        return this.$refs.carousel ? this.$refs.carousel.currentIndex : 0;
      }
    }
  },
  mounted () {
    this.getRecommedLessons()
    this.getChannel()
    if (this.authorized) {
      this.$router.push({ path: 'Login' })
    }
  },
  methods: {
    ...mapActions({
      dologin: 'login',
      getUser: 'getUser',
      dologout: 'logout'
    }),
    openLogin () {
      this.toggle = true
      this.isLogin = true
    },
    close () {
      this.toggle = false
      this.isLogin = false
      this.current = ''
    },
    async getRecommedLessons() {
      const { data } = await api.get('contents/courses/recommend')
      // let item = data.data[Math.floor(Math.random()*data.data.length)]
      this.recommendLesson = data.data
    },
    async getChannel () {
      const { data } = await api.get('/contents/channels')
      this.channels = data.data
    },
    goCourse (id) {
      this.$router.push({ name: 'Course', params: {channel: id} })
    },
    async login () {
      const vm = this
      try {
        await this.dologin({ email: this.auth.account, password: this.auth.password })
        await this.getUser()
        this.toggle = false
        this.isLogin = false
        // }
      } catch (error) {
        if (error) {
          this.setTimeout(
            this.$swal({
            title: '登入失敗',
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: '確定'
          })
          , 1000)
        }
      }
    },
    logout () {
      this.$router.push({ name: 'Login' })
      this.dologout()
    },
    goLesson (index) {
      this.$router.push({ name: 'Channels', params: { channel: index } })
    }
  },
  components: {
    Footer
    // Video
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/styles/custom.scss";
  .login-bg {
    position: relative;
  }
  .group {
    position:relative;
    margin-bottom: 35px;
  }
  input {
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #757575;
  }
  input:focus	{ outline: none; }

  /* LABEL ======================================= */
  label {
    color: #999;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  /* active state */
  input:focus ~ label, input:valid ~ label {
    top: -20px;
    font-size: 14px;
    color: #000000;
  }
  .arrow {
    width: 50px;
  }
  /* BOTTOM BARS ================================= */
  .bar {
    position:relative;
    display:block;
    width:100%;
  }
  .bar:before, .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #000000;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  .bar:before {
    left: 50%;
  }
  .bar:after {
    right: 50%;
  }

  /* active state */
  input:focus ~ .bar:before, input:focus ~ .bar:after {
    width: 50%;
  }
  .pop-up {
    text-align: left;
    width: 100%;
    height: 103vh;
    background-color: #000000ad !important;
    z-index: 1040;
    .login-box {
      @include center();
      padding: 40px;
      border-radius: 20px;
      box-shadow: 10px;
      background-color: #fff;
      width: 580px;
      max-height: 580px;
      box-shadow: 10px 12px 12px 12px rgba(0, 0, 0, 0.4);
      z-index: 1000;
      margin: auto;
    }
    .right-1,.left-1 {
      filter: brightness(40%);
    }
    .map-country {
      position: absolute;
      left: 20%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1000;
    }
    .carousel {
      @include center();
      padding: 20px;
      margin-left: 10%;
      .slide {
        margin: auto;
        padding: 50px;
        border: solid 1px transparent;
        background-color: transparent;
        .card {
          border-radius: 20px;
          overflow: hidden;
          box-shadow:  0px 0px 5px #fff;
          width: 100%;
        }
        .card-body {
          padding: 10px;
          color: #fff;
          background-color: $sub-color;
          .card-title {
            margin: 0;
            font-size: 2rem;
            text-align: center;
            .lesson-btn {
              float: right;
            }
          }
        }
        .card-title:hover{
          color: rgb(59, 253, 221);
        }
      }
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  .earth {
    position: relative;
    .earth-bg {
      cursor: pointer;
      position: relative;
      width: 100%;
    }
    .city-bg {
      width: 100%;
      position: absolute;
      left: 0%;
      top: 0%;
    }
    .line {
      width: 100%;
      position: absolute;
      left: 0%;
      top: 15%;
      .country {
        z-index: 999;
        margin-top: -13px;
        cursor: pointer;
        .country-box {
          min-width: 100px;
          font-size: 2rem;
          margin-top: 10px;
          padding: 5px;
          color: white;
          border: white 1px solid;
        }
      }
      .country:hover {
        .country-box {
          background-color: #fff;
          color: #000;
        }
      }
    }
    .isOpen {
      // bottom: -500px !important;
    }
    .ship-area {
      transition: 0.5s cubic-bezier(1,.08,.04,.87);
      cursor: pointer;
      width: 100%;
      position: absolute;
      left: 0%;
      top: 80%;
      .ship {
        width: 100%;
      }
      .content {
        // min-height: 100vh;
        background-color: #fff;
        h2 {
          font-size: 50px;
        }
      }
    }
  }
  @media screen and (max-width: 601px) {
    .login-box {
      width: 380px !important;
    }
    .rwd-banner {
      display: block !important;
    }
    .banner {
      background-attachment: scroll;
      // background-position: bottom;
      .title {
        font-size: 2.5rem !important;
      }
      .sub-title {
        font-size: 1.25rem !important;
      }
    }
    .pop-up {
      width: 100%;
    }
    .rwd-hide,.line {
      display: none !important;
    }
  }
  @media screen and (min-width: 601px) {
    .rwd-title {
      display: none;
    }
  }
</style>
