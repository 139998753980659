import Vue from 'vue'
import VueRouter from 'vue-router'
import Channels from '../views/Channels.vue'
import Course from '../views/Course.vue'
// import FirstLogin from '../views/FirstLogin.vue'
import UpdatedPassword from '../views/UpdatedPassword.vue'
import ChannelLesson from '../views/ChannelLesson.vue'
import CourseLesson from '../views/CourseLesson.vue'
import Login from '../views/Login.vue'
import Personal from '../views/Personal.vue'
import Record from '../components/Record.vue'
import Collections from '../components/Collections.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/loginByToken',
    name: 'LoginByToken',
    redirect: to => (
      { path: to.query.redirectUrl ? to.query.redirectUrl : '/' }
    )
  },
  // {
  //   path: '/first-login',
  //   name: 'First-login',
  //   component: FirstLogin
  // },
  {
    path: '/updated-password',
    name: 'Updated-password',
    component: UpdatedPassword
  },
  {
    path: '/channels/:channel',
    name: 'Channels',
    component: Channels
    // meta: { requiresAuth: true }
  },
  {
    path: '/lesson/:lessonId',
    name: 'ChannelLesson',
    component: ChannelLesson,
    meta: { requiresAuth: true }
  },
  {
    path: '/course/:channel',
    name: 'Course',
    component: Course
    // meta: { requiresAuth: true }
  },
  {
    path: '/course-lesson/:lesson/:courseId',
    name: 'CourseLesson',
    component: CourseLesson,
    meta: { requiresAuth: true }
  },
  {
    path: '/personal',
    name: 'Personal',
    component: Personal,
    meta: { requiresAuth: true },
    children: [
      { path: 'collections', name: 'Collections', component: Collections },
      { path: 'record', name: 'Record', component: Record }
    ]
  },
  // {
  //   path: '/director',
  //   name: 'Director',
  //   component: Director,
  //   children: [
  //     { path: 'overview/:type/:groupId?', name: 'Overview', component: Overview },
  //     { path: 'personal-detail/:type/:groupId?', name: 'PersonalDetail', component: PersonalDetail }
  //   ],
  //   meta: { requiresAuth: true }
  // },
  { path: '*', redirect: { name: 'Login' } }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
