import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAnalytics from 'vue-analytics'
import store from './store'
import { locales } from './locales/langs'
import 'bootstrap' // Import js file
import 'bootstrap/dist/css/bootstrap.min.css' // Import css file
import './assets/styles/main.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoffee, faBars, faArrowDown, faArrowRight, faTimes, faChevronRight, faEnvelopeOpenText, faEnvelope, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faGooglePlus } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import moment from 'moment'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueCarousel from 'vue-carousel'
import VueI18n from 'vue-i18n'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Carousel3d from 'vue-carousel-3d'

library.add(faCoffee, faGooglePlus, faBars, faArrowDown, faArrowRight, faTimes, faChevronRight, faEnvelopeOpenText, faEnvelope, faCheckCircle, faTimesCircle)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-select', vSelect)
Vue.use(VueCarousel)
Vue.use(Carousel3d)
Vue.use(VueSweetalert2)
Vue.use(VueI18n)

Vue.use(VueAnalytics, {
  id: 'UA-78537321-13',
  router,
  autoTracking: {
    pageviewOnLoad: false
  }
})

// Create VueI18n instance with options
const locale = window.localStorage.getItem('locale') || 'en'
const i18n = new VueI18n({
  locale: locale,
  messages: locales
})

Vue.config.productionTip = false
Vue.filter('dateFormat', function (daraStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(daraStr).format(pattern)
})
Vue.prototype.moment = moment

const vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    // console.log(store.state.auth.authorized)
    if (!store.state.auth.authorized) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})
export { vm }
