<template>
  <div id="app">
    <loading :active.sync="isLoading"></loading>
    <Navbar v-if="$route.name !== 'Login'" />
    <router-view :idleTime="count" :key="$route.fullPath" :class="{'main-info':$route.name !== 'Login'}"/>
    <Footer v-if="$route.name !== 'Login'"/>
  </div>
</template>
<script>
// @ is an alias to /src
import { mapGetters, mapActions } from 'vuex'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'App',
  data () {
    return {
      isloading: true,
      autoLogout: 15,
      count: 0
    }
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'authorized'
    ]),
    isHide() {
      return this.$route.name === 'CourseLesson'
    }
  },
  components: {
    Navbar,
    Loading,
    Footer
  },
  beforeDestroy () {
    this.clearTimer()
  },
  mounted () {
    if (this.$route.query.token) {
      const auth = {token: this.$route.query.token, isFirstLogin: false, isFinishPreTest: false, forcePreTest: false, enterpriseCode: 'liveabc'}
      this.$store.commit('setToken', auth)
    }
  },
  methods: {
    // ...mapActions({
    //   dologout: 'logout'
    // }),
    // clearTimer () {
    //   clearInterval(window.myTimer)
    //   window.myTimer = null
    // },
    // setTimer () {
    //   this.count = 0
    //   if (!window.myTimer) {
    //     window.myTimer = window.setInterval(this.cookieTimeout, 1000)
    //   }
    // },
    // cookieTimeout () {
    //   let outTime = this.autoLogout
    //   this.count++
    //   if (this.count === outTime * 60) {

    //   }
    //   if (this.count === outTime * 60) {
    //     setTimeout(this.logout, 10000)
    //   }
    // },
    // logout () {
    //   if (this.count >= this.autoLogout * 60) {
    //     this.$router.push({ name: 'Login' })
    //     this.$swal({
    //       title: '您已閒置15分鐘，已被登出',
    //       icon: 'info',
    //       showCancelButton: false,
    //       confirmButtonText: '確定'
    //     })
    //     this.dologout()
    //   }
    // }
  }
}
</script>

<style lang="scss">
* {
  font-family: Avenir, Helvetica, Arial, sans-serif, 微軟正黑體 !important;
  box-sizing: border-box;
}
#app {
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
}
.main-info {
  margin: 93px 0 0 0;
  min-height: calc(100vh - 93px);
}
.btn-general {
  border-radius: 20px !important;
  width: 150px;
}
input:focus {
  outline: none;
}

.success {
  font-size: 1.5rem;
  margin-top: 60px;
}
.error {
  text-align: left;
  color: red;
  font-size: .8rem;
}
::placeholder {
  color: rgb(165, 165, 165);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(165, 165, 165);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgb(165, 165, 165);
}
</style>
