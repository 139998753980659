<template>
  <div>
    <div class="container-fluid">
      <div class="row banner align-items-center">
        <div class="col-md-6 col-12">
          <div class="personal_info">
            <div class="d-flex justify-content-center">
              <div class="d-flex flex-column">
                <img class="circle_picture" src="../assets/image/pic/maxresdefault.jpg" />
              </div>
              <div class="ml-3">
                <div class="d-flex align-items-baseline">
                  <!-- <div class="cefr my-auto mr-2">{{personalData.level}}</div> -->
                  <h2>
                    {{personalData.name}}
                  </h2>
                  (<router-link style="color:#fff" :to="{ name: 'Updated-password'}">修改密碼</router-link>)
                </div>
                <p v-if="personalData.enterprise_name" class="mt-3">
                  學校名稱: {{personalData.enterprise_name}}
                </p>
                <!-- <p>
                  班別: {{personalData.group_name}}
                </p> -->
                <p class="mt-0">
                  課程期間: {{segment}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12 banner-box">
          <div class="container">
            <!-- <div class="row px-3 align-items-center">
              <div class="col-md-6 col-6" v-if="target">
                <div class="info-box">
                  <div class="title">
                    <img style="height:30px" src="../assets/image/pic/icon_class_time.png" alt="">
                    {{ $t('Personal.Info.Total_study_time') }}
                  </div>
                  <div class="content" v-if="target.time">
                    {{target.time.user}}
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-6" v-if="target">
                <div class="info-box">
                  <div class="title">
                    <img style="height:30px" src="../assets/image/pic/icon_login_time.png" alt="">
                    {{ $t('Personal.Info.First_time_login') }}
                  </div>
                  <div class="content" v-if="personalData.first_login_at">
                    {{moment(personalData.first_login_at).format('YYYY/MM/DD')}}
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="d-flex menu">
            <div @click="goPage('Record')" class="title  d-sm-flex pointer" :class="{'active' : $route.name === 'Record'}">
              <img style="height:30px" src="../assets/image/pic/icon_book.png" alt="">
              <div class="my-auto">
                學習紀錄
              </div>
            </div>
            <div @click="goPage('Collections')"  class="title d-sm-flex pointer" :class="{'active' : $route.name === 'Collections'}">
              <img style="height:30px" src="../assets/image/pic/icon_tag.png" alt="">
              <div class="my-auto">
                我的收藏
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <router-view :target="target"></router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
// import Video from '@/components/Video.vue'
import { mapGetters } from 'vuex'
import api from '../store/api'
export default {
  name: 'Personal',
  data () {
    return {
      personalData: {},
      target: {},
      groupId: 0
    }
  },
  computed: {
    segment () {
      return this.moment(this.personalData.group_begin_at).format('YYYY-MM-DD') + ' ~ ' + this.moment(this.personalData.group_end_at).format('YYYY-MM-DD')
    },
    ...mapGetters([
      'role',
      'authInfo'
    ])
  },
  mounted() {
    this.scrollTop()
    this.getPresonalData()
  },
  methods: {
    goPage (el) {
      this.$router.push({ name: el})
      if (el === 'HR') {
        this.$router.push({ name: 'Overview', params: {type: 'summary', groupId: this.personalData.group_id}})
      }
    },
    async getTarget (id) {
      const { data } = await api.get(`/records/students/${id}/learning_progress`)
      this.target = data.data
      this.target.segment = this.segment
      this.target.standard = this.personalData.enterprise_standard
    },
    async getPresonalData () {
      const { data } = await api.get('/me')
      this.personalData = data
      this.getTarget(data.student_id)
    },
    testAccount () {
      alert('測試帳號不提供此功能')
    },
    scrollTop () {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.scrollTo(0, 0)
      }
    }
  },
  components: {
    // Video
  }
}
</script>
<style lang="scss" scoped>
  .banner {
    min-height: 250px;
    // height: 30vh;
    height: 100%;
    background-image: url('../assets/image/pic/bg_member_top.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .personal_info {
      // margin: 0 30%;
      text-align: left;
      color: #fff;
    }
    .cefr {
      padding: 2px;
      font-size: 1rem;
      line-height: 1rem;
      background-color: rgb(255, 0, 0);
      border: 2px solid #fff;
      border-radius: 20%;
    }
    h2 {
      margin: 0;
    }
  }
  .main {
    background-color: #eee;
  }
  .circle_picture {
    width: 100px;
    height: 100px;
    border-radius:50%;
  }
  .active {
    color: red;
  }
  .info-box {
    margin: 10px;
    .title {
      padding: 3px;
      max-width: 200px;
      background-color: #fff;
      font-weight: bold;
      color: #51A8DD;
    }
    .content {
      padding: 10px;
      max-width: 200px;
      color: #fff;
      border: 1px #fff solid;
    }
  }
  .menu {
    .title {
      margin: 20px;
      font-weight: bolder;
    }
  }
  @media(max-width: 1080px){
    .banner-box {
      display: none;
    }
  }
</style>
